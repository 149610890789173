































































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { FormRegraFiscalDeProduto, RegimeFiscal, TipoDeTransacaoDeRegraFiscal, TipoEntradaSaidaRegraFiscal } from '@/models'
import SeletorDeRegraFiscal from '@/components/fiscal/SeletorDeRegraFiscal.vue'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import { listaDeRegimesFiscais } from '@/shareds/fiscal'
import { obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		SeletorDeRegraFiscal,
		SeletorDeRegimeFiscal,
	},
})
export default class EdicaoDeRegrasFiscaisDoProduto extends Vue {
	@PropSync('value') regrasFiscais!: FormRegraFiscalDeProduto[]

	obrigatorio = obrigatorio
	listaDeRegimesFiscais = listaDeRegimesFiscais

	tiposTransacao: TipoDeTransacaoDeRegraFiscal[] = ['Venda', 'Devolução', 'Transferência', 'Consignado', 'Demonstração', 'Brinde', 'Outros', 'Conserto', 'Ativo', 'Consumo', 'Não Configuradas']
	entradasESaidas: TipoEntradaSaidaRegraFiscal[] = ['Entrada', 'Saída', 'Não Configuradas']

	removerRegraFiscalDeProduto(indice: number) {
		this.regrasFiscais.splice(indice, 1)
	}

	opcoesDeRegime(regimeAtual: RegimeFiscal | null) {
		return listaDeRegimesFiscais.filter(
			regime =>
				regime === regimeAtual || !this.regimesSelecionados.includes(regime),
		)
	}

	get regimesSelecionados() {
		return this.regrasFiscais.map(({ tipo }) => tipo)
	}

	get temTodosOsRegimes() {
		return this.regimesSelecionados.length === listaDeRegimesFiscais.length
	}

	adicionarRegraFiscalDoProduto() {
		this.regrasFiscais.push({
			id: '',
			tipo: null,
			regra: null,
			transacao: 'Não Configuradas',
			entradaSaida: 'Não Configuradas',
		})
	}
}

